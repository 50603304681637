// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pi5xb{position:absolute;top:0;right:24px;z-index:1;display:inline-flex}.v0FTD{position:relative;width:56px;min-width:56px;height:56px;background-color:var(--wpp-primary-color-500);border-radius:50%;cursor:help}.oFHPy{cursor:pointer}.TODV0{max-width:calc(100% - 56px - 9px);height:auto;margin-right:9px;object-fit:contain;opacity:0;transition:opacity .5s}.W45Bq{opacity:1}.K8usp{width:36px}", "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/legend/Legend.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,KAAA,CACA,UAAA,CACA,SAAA,CACA,mBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,cAAA,CACA,WAAA,CACA,6CAAA,CACA,iBAAA,CACA,WAAA,CAGF,OACE,cAAA,CAGF,OACE,iCAAA,CACA,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,SAAA,CACA,sBAAA,CAGF,OACE,SAAA,CAGF,OACE,UAAA","sourcesContent":[".tooltipContainer {\n  position: absolute;\n  top: 0;\n  right: 24px;\n  z-index: 1;\n  display: inline-flex;\n}\n\n.legendTrigger {\n  position: relative;\n  width: 56px;\n  min-width: 56px;\n  height: 56px;\n  background-color: var(--wpp-primary-color-500);\n  border-radius: 50%;\n  cursor: help;\n}\n\n.legendSideModalTrigger {\n  cursor: pointer;\n}\n\n.tooltipImg {\n  max-width: calc(100% - 56px - 9px);\n  height: auto;\n  margin-right: 9px;\n  object-fit: contain;\n  opacity: 0;\n  transition: opacity 0.5s;\n}\n\n.tooltipVisible {\n  opacity: 1;\n}\n\n.icon {\n  width: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": "Pi5xb",
	"legendTrigger": "v0FTD",
	"legendSideModalTrigger": "oFHPy",
	"tooltipImg": "TODV0",
	"tooltipVisible": "W45Bq",
	"icon": "K8usp"
};
export default ___CSS_LOADER_EXPORT___;
