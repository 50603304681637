import { WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/phaseBuilder/legend/Legend.module.scss'
import {
  hideLegendSideModal,
  showLegendSideModal,
  useLegendSideModal,
} from 'pages/phaseBuilder/legendSideModal/LegendSideModal'
import { PhaseFormConfig, PhaseLegendVariant } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

interface Props {
  config: PhaseFormConfig
}

export const Legend = ({ config }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const { visible: isLegendSideModalVisible } = useLegendSideModal()

  const handleShowTooltip = () => {
    setIsTooltipShown(true)
  }

  const HandleHideTooltip = () => {
    setIsTooltipShown(false)
  }

  const handleToggleLegendSideModal = useCallback(() => {
    if (config?.legend?.variant === PhaseLegendVariant.TOOLTIP) return

    if (isLegendSideModalVisible) {
      hideLegendSideModal()
    } else {
      showLegendSideModal({ config })
    }
  }, [config, isLegendSideModalVisible])

  if (!config?.legend?.isEnabled) return null

  return (
    <Flex gap={9} className={styles.tooltipContainer}>
      {config?.legend?.variant === PhaseLegendVariant.TOOLTIP && config?.legend?.image?.[0] && (
        <img
          src={getPreviewImageUrl(config.legend.image)}
          alt="Process Legend"
          className={clsx(styles.tooltipImg, isTooltipShown && styles.tooltipVisible)}
        />
      )}
      <WppTooltip
        text={config?.legend?.iconTooltip ?? undefined}
        className={styles.textTooltip}
        config={{
          placement: 'left',
          arrow: false,
          onShow: () => {
            if (config?.legend?.variant === PhaseLegendVariant.SIDEBAR && config?.legend?.iconTooltip) {
              return
            }
            return false
          },
        }}
      >
        <Flex
          justify="center"
          align="center"
          className={clsx(styles.legendTrigger, {
            [styles.legendSideModalTrigger]: config?.legend?.variant === PhaseLegendVariant.SIDEBAR,
          })}
          style={{ backgroundColor: config?.legend?.colors?.[0] }}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={HandleHideTooltip}
          onClick={handleToggleLegendSideModal}
        >
          {config?.legend?.icon?.[0] ? (
            <img src={getPreviewImageUrl(config.legend.icon)} className={styles.icon} alt="Process Legend Icon" />
          ) : (
            <InfoIcon stroke={config?.legend?.colors?.[1] || '#ffffff'} />
          )}
        </Flex>
      </WppTooltip>
    </Flex>
  )
}
